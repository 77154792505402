import React from "react";
import * as PropTypes from "prop-types";
import ProductType from "./productType";
import { Box, SimpleGrid } from "@chakra-ui/core";
import ProductPreview from "./productPreview";
import { SubHeading } from "../headings";
import Separator from "../MarkDown/separator";
import { useIntl } from "gatsby-plugin-intl";
import { getAttributeTranslated } from "../../util/entities"

const ProductList = ({
  products, showSections, defaultSection, ...rest
}) => {
  const intl = useIntl();
  if (products.length === 0) {
    return null;
  }
  const sections = {
    default: {
      name: intl.formatMessage({ id: "products.defaultSection" }),
      products: [],
    },
  };
  if (showSections) {
    products.forEach((product) => {
      if (product.section && product.section.trim().length > 0) {
        if (!sections[product.section]) {
          sections[product.section] = {
            name: getAttributeTranslated(product, 'section', intl.locale),
            products: [],
          };
        }
        sections[product.section].products.push(product);
      } else {
        sections.default.products.push(product);
      }
    });
  } else {
    sections.default.products.push(...products);
  }
  return (
    <Box {...rest}>
      {Object.values(sections).map(section => {
        return section.products.length > 0 && (
          <React.Fragment key={section.name}>
            <Separator mt={6} />
            <SubHeading as="h3">
              {section.name}
            </SubHeading>
            <SimpleGrid columns={[2, 3, 4, 4]} spacing={8}>
              {section.products.map(product => (
                <ProductPreview key={product.id} product={product} />
              ))}
            </SimpleGrid>
          </React.Fragment>
        )
      })}
    </Box>
  );
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(ProductType),
  showSections: PropTypes.bool,
  defaultSection: PropTypes.string,
};

ProductList.defaultProps = {
  products: null,
  showSectons: false,
  defaultSection: null,
};

export default ProductList;
