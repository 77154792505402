import React from "react";
import { Link } from "gatsby-plugin-intl";
import { Heading, Box } from "@chakra-ui/core";
import ProductType from "./productType";
import MaclicImage from "../images/maclicImage";
import { useIntl } from "gatsby-plugin-intl";
import { getAttributeTranslated } from "../../util/entities"

const ProductPreview = ({ product, ...rest }) => {
  const intl = useIntl();
  const link = product.fields ? product.fields.slug : "#";
  return (
    <Box>
      <Box shadow="md" borderWidth="1px" {...rest}>
        <Link to={link}>
          <MaclicImage size="previewThird" src={product.photo}/>
        </Link>
        <Box
          w="100%"
          py={2}
          px={4}
        >
          <Link to={link}>
            <Heading as="h2" size="xs" fontWeight={600}>
              {getAttributeTranslated(product, "name", intl.locale)}
            </Heading>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

ProductPreview.propTypes = {
  product: ProductType.isRequired,
};

export default ProductPreview;
