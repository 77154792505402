import * as PropTypes from "prop-types";

const ProductType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  photo: PropTypes.string,
  fields: PropTypes.shape({
    slug: PropTypes.string,
  }),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  priceTo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  priceFixed: PropTypes.bool,
});

export default ProductType;
