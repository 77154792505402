/** @jsx jsx */
import { jsx } from "@emotion/core";
// import * as PropTypes from "prop-types";
import { Box, Text } from "@chakra-ui/core"
import ProductType from "./productType"
import { useIntl } from "gatsby-plugin-intl"

const getPrice = (number, locale = "es") => number
  .toLocaleString(locale, { style: "currency", currency: "COP" });

const Price = ({ product, ...props }) => {
  const intl = useIntl();
  if (product) {
    const { price, priceTo, priceFixed } = product;
    if (typeof price === 'number' && price > 0) {
      let thePrice = getPrice(price, intl.locale);
      if (!priceFixed) {
        if (typeof priceTo === 'number' && priceTo > price) {
          const thePriceTo = getPrice(priceTo, intl.locale);
          thePrice = `${thePrice} - ${thePriceTo}`;
        } else {
          thePrice = `${intl.formatMessage({ id: "product.from" })} ${thePrice}`;
        }
      }
      return (
        <Box
          borderTopWidth={1}
          borderBottomWidth={1}
          py={4}
          mb={4}
          textAlign="right"
          {...props}
        >
          <Text float="left">
            {intl.formatMessage({ id: "product.price" })}
          </Text>
          <Text ml={2} as="strong" fontSize="xl">
            {thePrice}
          </Text>
        </Box>
      );
    }
  }
  return null;
};

Price.propTypes = {
  product: ProductType,
};

Price.defaultProps = {
  product: null,
};

export default Price;
