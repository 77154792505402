import React from "react";
import { Link } from "gatsby-plugin-intl";
import { Box, SimpleGrid, Tag } from "@chakra-ui/core";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import MaclicImage from "../components/images/maclicImage";
import { SecondaryHeading, CategoryHeading } from "../components/headings";
import MarkDown from "../components/MarkDown";
import MainLayout from "../components/layout/main";
import { getAttributeTranslated } from "../util/entities";
import ProductList from "../components/products/productList";
import Price from "../components/products/price";
import { useIntl } from "gatsby-plugin-intl";
import { useRegisterScreen } from "../util/firebaseFunctions"

export default ({ data }) => {
  const { product, allProduct } = data;
  const intl = useIntl();
  if (!!product) {
    useRegisterScreen(product.name);
    const details = getAttributeTranslated(product, 'details', intl.locale);
    const by = intl.formatMessage({ id: "util.by" });
    const name = getAttributeTranslated(product, "name", intl.locale);
    const place = getAttributeTranslated(product.parent, 'name', 'es');
    const title = `${name} ${by} ${place}`;
    return (
      <MainLayout>
        <SEO
          description={details}
          image={product.photo}
          pathname={product.fields.slug}
          title={title}
          type="product"
        />
        <SimpleGrid
          minChildWidth="250px"
          spacing="40px"
        >
          <Box>
            <MaclicImage
              boxShadow="xl"
              mt={[-4, -4, 8, 8]}
              mx={[-4, -5, 0, 0]}
              src={product.photo}
              size="product"
            />
          </Box>
          <Box mt={[-8, -6, 0, 0]}>
            <CategoryHeading fontStyle="italic" mt={8}>
              {getAttributeTranslated(product, "section", intl.locale)}
            </CategoryHeading>
            <SecondaryHeading fontSize="3xl" mt={0} mb={0}>
              {name}
            </SecondaryHeading>
            <Box mt={0} mb={6}>
              <Link to={product.parent.fields.slug}>
                <Tag
                  minHeight="1rem"
                  rounded="full"
                  variantColor="cyan"
                  size="sm"
                  textAlign="center"
                >
                  {place}
                </Tag>
              </Link>
            </Box>
            <Price product={product} />
            <MarkDown md={details} />
          </Box>
        </SimpleGrid>
        {!!allProduct && (
          <>
            <SecondaryHeading size="lg" as="h2">
              {`${intl.formatMessage({ id: "util.more"})} ${place}`}
            </SecondaryHeading>
            <ProductList
              showSections
              products={allProduct.nodes}
            />
          </>
        )}
      </MainLayout>
    );
  }
  return null;
}

export const query = graphql`
  query($id: String!, $parent: String!) {
    product(id: {eq: $id}) {
      details
      details_en
      id
      name
      name_en
      photo
      price
      priceFixed
      priceTo
      section
      section_en
      fields {
        slug
      }
      parent {
        ... on Place {
          id
          name
          name_en
          fields {
            slug
          }
        }
      }
    }
    allProduct(filter: {parent: {id: {eq: $parent}}, id: {ne: $id}}) {
      nodes {
        name
        name_en
        photo
        section
        section_en
        fields {
          slug
        }
      }
    }
  }
`;
